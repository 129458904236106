import { dia, ui, shapes, elementTools, util, V, g } from '@clientio/rappid';
import JointSettings from '../../JointSettings.js';

import JointItem from '../items/JointItem.js';
import JointItemConf from '../items/JointItemConf.js';
import { JointItemTypes } from '../items/JointTypes.js';

class JointResourceAxis {
	constructor(jointMain, topAxisHeight, leftAxisWidth, resources) {
		this.jointMain = jointMain;

		this.resources = resources;

		this.topAxisHeight = topAxisHeight;
		this.leftAxisWidth = leftAxisWidth;

		this.itemHeight = JointSettings.axis.bollardHeight * 2;
	}

	render() {
		this.renderItems();
		this.renderItemDelimiters();
		this.renderFinalInvisibleItem();
	}

	// RECURSOS
	renderItems() {
		for (let n = 0; n < this.resources.length; n++) {
			var jointItemConf = new JointItemConf(
				JointItemTypes.ResourceName,
				this.getResourceNameLabel(this.resources[n]),
				0,
				this.topAxisHeight + this.itemHeight * n,
				this.leftAxisWidth,
				this.itemHeight
			);

			new JointItem(jointItemConf, this.jointMain).render();
		}
	}

	// DELIMITADORES DE DIA
	renderItemDelimiters() {
		for (let n = 0; n <= this.resources.length; n++) {
			var line = V('line', {
				x1: 0,
				y1: this.topAxisHeight + n * this.itemHeight,
				x2: this.jointMain.paperWidth,
				y2: this.topAxisHeight + n * this.itemHeight,
				stroke: JointSettings.colors.grid.lines,
				'stroke-width': 1
			});

			V(this.jointMain.paper.viewport).append(line);
		}
	}

	// MEDIA HORA INVISIBLE FINAL (centrar labels en los ejes)
	renderFinalInvisibleItem() {
		var jointItemConf = new JointItemConf(
			JointItemTypes.ResourceName,
			'',
			0,
			this.topAxisHeight + this.itemHeight * this.resources.length,
			this.leftAxisWidth,
			this.jointMain.paperHeight
		);

		new JointItem(jointItemConf, this.jointMain).render();
	}

	getResourceNameLabel(resourceAllocation) {
		return resourceAllocation.description;
	}

	// returns 'n' correspondant lane
	getYpositionFromResourceId(resourceid) {
		let lane = null;

		this.resources.forEach((resource, index) => {
			if (resource.resourceid == resourceid) {
				lane = index;
			}
		});

		return lane;
	}

	getClosestResourceFromYposition(y) {
		for (let n = 1; n <= this.resources.length; n++) {
			if (y < this.topAxisHeight + this.itemHeight * n) {
				return this.resources[n - 1];
			}
		}
	}
}

export default JointResourceAxis;
